import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  AUTH_HEADER:
    'Bearer eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.xeud6MOXWXE7brL__AKQAQVE2BYnML-rJy-WHfLlY7sWlrX_ld39gDolladaSJj4yhzhhavQx1Cc82szNO7Sl0txCMVACOFa.ngXYB0cDUJEpwbPZmBmi_A.9AqPURtaUKd8SjBxthkndgEvNkfT0cgQlGxtJ_KpmARYeEVfmvVw9RDW44BrGceuOyx-9RBEVvf8XBs2Bg1-n8AD2PInxh6qyx-tWKxDX7eS4HE6_Arty234A0_jMXiWvYGTAT9qM3ffd2J8F2AjLw.m2YrM844jJvqptYdTlsNDIC1dVyTPKRAsmla2kprAT0',
  CONNECT: '',
  FIRST_NAME: '',
  LAST_NAME: '',
  PRIMARY_ROLE: '',
  SELECTED: '',
  USER_EMAIL: '',
  USER_ID: '',
  USER_NAME: '',
  __env__: 'Production',
};
export const AppVariables = {
  ALL_CHATS: '',
  AVERAGE_OPENING_OPTIONS: [],
  CHATROOM_ID: [],
  CHATROOM_USER: [],
  COMPANY_OPTIONS: [],
  DEMO_USER: false,
  DESIRED_SALARY_OPTIONS: [],
  error_message: '',
  HEADCOUNT_OPTIONS: [],
  INDUSTY_OPTIONS: [],
  INSTITUTION_OPTIONS: [],
  is_loading: false,
  JOB_ID: '',
  JOB_LEVEL_OPTIONS: [],
  JOB_SITE_OPTIONS: [],
  JOB_TYPE_OPTIONS: [],
  JOBZSTORY: [],
  LAST_MESSAGE: '',
  NO_MANAGED_OPTIONS: [],
  PV_CHATROOM_USER: '',
  PV_COMPANY: '',
  PV_DESIRED_SALARY: '',
  PV_HEADCOUNT: '',
  PV_INDUSTRY: '',
  PV_INSTITION: '',
  PV_JOB_LEVEL: '',
  PV_JOB_SITE: '',
  PV_JOB_TYPE: '',
  PV_NO_MANAGED_PEOPLE: '',
  PV_REVENUE: '',
  PV_TEAM_SIZE: '',
  REVENUE_OPTIONS: [],
  SCREEN_COMPANY_SEARCH_BLOCK: true,
  SCREEN_INDUSTRY_SEARCH_BLOCK: false,
  SHOW_INDUSTRY: false,
  SHOW_RIGHT: false,
  SHOW_SIDEBAR: false,
  SHOW_WRONG: false,
  TEAM_SIZE_OPTIONS: [],
  uploadIMAGE: 'https://via.placeholder.com/350x150',
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
