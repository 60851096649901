import React from 'react';
import {
  Button,
  IconButton,
  Pressable,
  ScreenContainer,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobseekerApi from '../apis/XanoJobseekerApi.js';
import * as XanoUsersApi from '../apis/XanoUsersApi.js';
import SidebarMenuBlock from '../components/SidebarMenuBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { user_id: null };

const ProfileJobseekerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [INPUT_firstName, setINPUT_firstName] = React.useState(
    Constants['FIRST_NAME']
  );
  const [INPUT_lastName, setINPUT_lastName] = React.useState(
    Constants['LAST_NAME']
  );
  const [SV_Visibility, setSV_Visibility] = React.useState(true);
  const [SV_cell, setSV_cell] = React.useState(false);
  const [SV_email, setSV_email] = React.useState(false);
  const [image_profile, setImage_profile] = React.useState('');
  const [showYourDone, setShowYourDone] = React.useState(false);
  const [show_homeContainer, setShow_homeContainer] = React.useState(true);
  const [show_imageContainer, setShow_imageContainer] = React.useState(false);
  const [show_nameContainer, setShow_nameContainer] = React.useState(false);
  const [show_profileImage, setShow_profileImage] = React.useState(true);
  const [show_settingContainer, setShow_settingContainer] =
    React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <SidebarMenuBlock />
          {/* SCREEN CONTAINER */}
          <View>
            {/* MAIN CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { paddingTop: 20, position: 'relative' },
                dimensions.width
              )}
            >
              <XanoUsersApi.FetchGetUserProfileGET
                handlers={{
                  onData: fetchData => {
                    try {
                      setGlobalVariableValue({
                        key: 'FIRST_NAME',
                        value: fetchData?.first_name,
                      });
                      setGlobalVariableValue({
                        key: 'LAST_NAME',
                        value: fetchData?.last_name,
                      });
                      setGlobalVariableValue({
                        key: 'USER_EMAIL',
                        value: fetchData?.email,
                      });
                      setGlobalVariableValue({
                        key: 'PRIMARY_ROLE',
                        value: fetchData?._os_externalrole?.external_role,
                      });
                      setImage_profile(fetchData?._image_of_user?.image?.url);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                user_id={2}
              >
                {({ loading, error, data, refetchGetUserProfile }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Profile Image */}
                      <>
                        {!(show_imageContainer === false) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center' },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  setShow_imageContainer(true);
                                  setShow_homeContainer(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={imageSource(
                                  `${fetchData?._image_of_user?.image?.url}`
                                )}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  dimensions.width
                                )}
                              />
                            </Pressable>
                            {/* First and Last Name */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['h3'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['h3'].style,
                                dimensions.width
                              )}
                            >
                              {fetchData?.first_name} {fetchData?.last_name}
                            </Text>
                          </View>
                        )}
                      </>
                    </>
                  );
                }}
              </XanoUsersApi.FetchGetUserProfileGET>
            </View>
            {/* HOME CONTAINER */}
            <>
              {!(show_homeContainer === true) ? null : (
                <View>
                  {/* TOP OF BLOCK */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['Primary 3'],
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Name Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Name */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Name'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            setShow_homeContainer(false);
                            setShow_nameContainer(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        icon={'AntDesign/right'}
                        size={18}
                      />
                    </View>
                  </View>
                  {/* CENTRAL BLOCK Profile Picture */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['Primary 3'],
                        marginLeft: 20,
                        marginRight: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Profile Picture */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Profile Picture'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            setShow_profileImage(false);
                            setShow_homeContainer(false);
                            setShow_imageContainer(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        icon={'AntDesign/right'}
                        size={18}
                      />
                    </View>
                  </View>
                  {/* CENTRAL BLOCK Settings */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['Primary 3'],
                        marginLeft: 20,
                        marginRight: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Settings */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Settings'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            setShow_settingContainer(true);
                            setShow_homeContainer(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        icon={'AntDesign/right'}
                        size={18}
                      />
                    </View>
                  </View>
                  {/* BOTTOM BLOCK  */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        borderColor: palettes.App['Primary 3'],
                        marginLeft: 20,
                        marginRight: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: 0,
                          paddingLeft: 0,
                        },
                        dimensions.width
                      )}
                    >
                      {/* logout */}
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: true,
                            });
                            setGlobalVariableValue({
                              key: 'AUTH_HEADER',
                              value: '',
                            });
                            undefined;
                            undefined;
                            undefined;
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: false,
                            });
                            navigation.navigate('LoginScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: null,
                            color: theme.colors.branding.primary,
                            fontFamily: 'Poppins_700Bold',
                            marginBottom: 0,
                            marginTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingTop: 0,
                          },
                          dimensions.width
                        )}
                        title={'Log out'}
                      />
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* NAME CONTAINER */}
            <>
              {!(show_nameContainer === true) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 20, marginRight: 20 },
                    dimensions.width
                  )}
                >
                  <View>
                    {/* Name */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['label'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['label'].style,
                        dimensions.width
                      )}
                    >
                      {'Name'}
                    </Text>
                  </View>
                  {/* TOP OF BLOCK */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['Primary 3'],
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* First Name Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL First Name */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'First Name'}
                      </Text>
                      {/* Input_firstName */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newInputFirstNameValue => {
                          try {
                            setINPUT_firstName(newInputFirstNameValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['Text Input']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input']
                              .style,
                            {
                              borderBottomWidth: null,
                              borderColor: null,
                              borderLeftWidth: null,
                              borderRightWidth: null,
                              borderTopWidth: null,
                              minWidth: '100%',
                              paddingBottom: 4,
                              paddingLeft: 4,
                              paddingRight: 4,
                              paddingTop: 4,
                            }
                          ),
                          dimensions.width
                        )}
                        value={INPUT_firstName}
                      />
                    </View>
                  </View>
                  {/* BOTTOM OF BLOCK */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Last Name Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Last Name */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Last Name'}
                      </Text>
                      {/* Input_lastName */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newInputLastNameValue => {
                          try {
                            setINPUT_lastName(newInputLastNameValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['Text Input']
                          .props}
                        placeholder={INPUT_lastName.toString()}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input']
                              .style,
                            {
                              borderBottomWidth: null,
                              borderColor: null,
                              borderLeftWidth: null,
                              borderRightWidth: null,
                              borderTopWidth: null,
                              minWidth: '100%',
                              paddingBottom: 4,
                              paddingLeft: 4,
                              paddingRight: 4,
                              paddingTop: 4,
                            }
                          ),
                          dimensions.width
                        )}
                        value={INPUT_lastName}
                      />
                    </View>
                  </View>
                  {/* BUTTON BLOCK */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Button Update */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                    {/* Button Cancel */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShow_homeContainer(true);
                          setShow_nameContainer(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['button_CLEAR']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* IMAGE CONTAINER */}
            <>
              {!(show_imageContainer === true) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(`${image_profile}`)}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          borderColor: theme.colors.border.brand,
                          borderWidth: 1,
                          height: 200,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        gap: 10,
                        justifyContent: 'space-evenly',
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Browse */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const uploadimageResult = await openImagePickerUtil(
                              {
                                mediaTypes: 'Images',
                                allowsEditing: false,
                                quality: 0.2,
                                allowsMultipleSelection: false,
                                outputBase64: true,
                              }
                            );

                            setGlobalVariableValue({
                              key: 'uploadIMAGE',
                              value: uploadimageResult,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 100,
                          color: theme.colors.branding.primary,
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                          minWidth: 40,
                        },
                        dimensions.width
                      )}
                      title={'Browse'}
                    />
                  </View>
                  {/* Button Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Button Update */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await XanoJobseekerApi.oNBOARDINGUploadProfilePicturePATCH(
                                Constants,
                                { content: image_profile }
                              )
                            )?.json;
                            setShow_imageContainer(false);
                            setShow_homeContainer(true);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                    {/* Button Cancel */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShow_homeContainer(true);
                          setShow_imageContainer(false);
                          setShow_profileImage(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['button_CLEAR']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* SETTING CONTAINER */}
            <>
              {!(show_settingContainer === true) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 20, marginRight: 20 },
                    dimensions.width
                  )}
                >
                  {/* Heading */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 20, paddingTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* LABEL Settings */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['h2'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['h2'].style,
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                  </View>
                  {/* SETTING BLOCK */}
                  <View
                    style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}
                  >
                    {/* Visability */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Visability */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Visible'}
                      </Text>
                      {/* Switch Visability */}
                      <Switch
                        onValueChange={newSwitchVisabilityValue => {
                          const switchValue = newSwitchVisabilityValue;
                          try {
                            setSV_Visibility(newSwitchVisabilityValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        value={SV_Visibility}
                      />
                    </View>
                    {/* Email */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Email */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Show Email'}
                      </Text>
                      {/* Switch Email */}
                      <Switch
                        onValueChange={newSwitchEmailValue => {
                          try {
                            setSV_email(newSwitchEmailValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        value={SV_email}
                      />
                    </View>
                    {/* Cell */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Cell */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Show Cell'}
                      </Text>
                      {/* Switch Cell */}
                      <Switch
                        onValueChange={newSwitchCellValue => {
                          try {
                            setSV_cell(newSwitchCellValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        value={SV_cell}
                      />
                    </View>
                  </View>
                  {/* BUTTON BLOCK */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Button Update */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                    {/* Button Cancel */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShow_homeContainer(true);
                          setShow_settingContainer(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['button_CLEAR']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProfileJobseekerScreen);
