import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { newCompanyResponse: null, newLocationResult: null };

const AddNewCompanyScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ID_state, setID_state] = React.useState(0);
  const [INPUT_Company, setINPUT_Company] = React.useState('');
  const [INPUT_Location, setINPUT_Location] = React.useState('');
  const [PLACEHOLDER_Company, setPLACEHOLDER_Company] = React.useState(
    'Enter your Company Name'
  );
  const [PLACEHOLDER_FindLocation, setPLACEHOLDER_FindLocation] =
    React.useState('Find your Location');
  const [PLACEHOLDER_Location, setPLACEHOLDER_Location] =
    React.useState('Find your Location');
  const [isEditingLocation, setIsEditingLocation] = React.useState(false);
  const [searchLocationValue, setSearchLocationValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setINPUT_Location(
        props.route?.params?.newLocationResult ?? defaultProps.newLocationResult
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* ADD NEW COMPANY RECORD */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.Brand['Strong Inverse'],
                flex: 1,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* back navigation */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Back */}
              <IconButton
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/arrowleft'}
              />
            </View>
            {/* HEADING Add Your Company */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                  marginLeft: 20,
                  marginTop: 10,
                }),
                dimensions.width
              )}
            >
              {'Add Your Company'}
            </Text>
            {/* INPUT CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20, marginRight: 20, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* LABEL Add Company */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h3'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h3'].style,
                  dimensions.width
                )}
              >
                {'Company'}
              </Text>
              {/* Input_Company */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputCompanyValue => {
                  try {
                    setINPUT_Company(newInputCompanyValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                placeholder={PLACEHOLDER_Company.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['InputField'].style,
                  dimensions.width
                )}
                value={INPUT_Company}
              />
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Location Add Block */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                    dimensions.width
                  )}
                >
                  {/* LABEL Location */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['label'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['label'].style,
                      dimensions.width
                    )}
                  >
                    {'Location*'}
                  </Text>

                  <Pressable
                    onPress={() => {
                      try {
                        navigation.navigate('AddNewLocationScreen', {
                          newCompany:
                            props.route?.params?.newCompanyResponse ??
                            defaultProps.newCompanyResponse,
                          newLocation: searchLocationValue,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Add Location */}
                    <Icon
                      size={24}
                      {...GlobalStyles.IconStyles(theme)['icon_secondary']
                        .props}
                      name={'AntDesign/plus'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                        dimensions.width
                      )}
                    />
                  </Pressable>
                </View>
                {/* Find Location */}
                <View>
                  {/* Input Location */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newInputLocationValue => {
                      try {
                        const valueY09PiBdK = newInputLocationValue;
                        const newLocationResult = valueY09PiBdK;
                        setSearchLocationValue(valueY09PiBdK);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onChangeTextDelayed={newInputLocationValue => {
                      try {
                        if (newInputLocationValue?.length > 0) {
                        } else {
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onFocus={() => {
                      try {
                        setIsEditingLocation(true);
                        setSearchLocationValue('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                    placeholder={PLACEHOLDER_FindLocation.toString()}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['InputField'].style,
                        { borderRadius: 18 }
                      ),
                      dimensions.width
                    )}
                    value={searchLocationValue}
                  />
                  <>
                    {!(
                      searchLocationValue?.length && isEditingLocation
                    ) ? null : (
                      <XanoOptionSets$DropdownsApi.FetchDropDownLocationGET
                        handlers={{
                          onData: fetchData => {
                            try {
                              console.log();
                            } catch (err) {
                              console.error(err);
                            }
                          },
                        }}
                        search={searchLocationValue}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchDropDownLocation,
                        }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <>
                              {/* MESSAGE Add Your Location */}
                              <>
                                {fetchData?.dd_location?.length ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'You will need to add your location, click on +'
                                    }
                                  </Text>
                                )}
                              </>
                              <>
                                {!(searchLocationValue?.length > 2) ? null : (
                                  <SimpleStyleFlatList
                                    data={fetchData?.dd_location}
                                    decelerationRate={'normal'}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={'l8qmytO9'}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    pagingEnabled={false}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setSearchLocationValue(
                                                listData?.city
                                              );
                                              undefined;
                                              setID_state(listData?.id);
                                              setIsEditingLocation(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          {/* Location to Select */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.city}
                                            {', '}
                                            {listData?._os_states_usa?.state}
                                          </Text>
                                        </Pressable>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    snapToAlignment={'start'}
                                  />
                                )}
                              </>
                            </>
                          );
                        }}
                      </XanoOptionSets$DropdownsApi.FetchDropDownLocationGET>
                    )}
                  </>
                </View>
              </View>
            </View>
            {/* BUTTON SAVE COMPANY */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  margin: 20,
                },
                dimensions.width
              )}
            >
              {/* Button_Save Company */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const newCompanyResponse = (
                        await XanoOptionSets$DropdownsApi.addANewCompanyPOST(
                          Constants,
                          { company_name: INPUT_Company, location_id: ID_state }
                        )
                      )?.json;
                      navigation.navigate('JobzStoryAddScreen', {
                        NewCompanyResponse: newCompanyResponse,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                    {
                      fontFamily: 'Poppins_700Bold',
                      height: null,
                      maxWidth: 60,
                    }
                  ),
                  dimensions.width
                )}
                title={'Save'}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddNewCompanyScreen);
