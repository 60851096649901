import React from 'react';
import {
  Button,
  Pressable,
  ScreenContainer,
  Switch,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobseekerApi from '../apis/XanoJobseekerApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { user_ID: '' };

const Onboarding1JobseekerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [switchValueQ1, setSwitchValueQ1] = React.useState(false);
  const [switchValueQ2, setSwitchValueQ2] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View style={StyleSheet.applyWidth({ margin: 20 }, dimensions.width)}>
            {/* Heading Section */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 10 },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(
                  Images['ElephantLookingLeftJobzMaverickFinal']
                )}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { alignSelf: 'flex-start' }
                  ),
                  dimensions.width
                )}
              >
                {"Let's get you started . . ."}
              </Text>
            </View>
            {/* Description */}
            <View>
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  "Before you can look for your next great opportunity or even connect with potential companies, we need to know a little more about you. All fields can be modified later, but should be completed now.\n\nSo grab a cup and let's begin and it will be all completed before you finish your drink."
                }
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 10,
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Block Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'Tell us more about you . . .'}
              </Text>
              {/* Q1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row-reverse',
                    gap: 40,
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* Currently Employed */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Are you currently employed?'}
                </Text>
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSwitchValueQ1(newSwitchValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={switchValueQ1}
                />
              </View>
              {/* Q2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row-reverse',
                    gap: 40,
                    justifyContent: 'space-around',
                  },
                  dimensions.width
                )}
              >
                {/* Currently Employed */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Are you currently actively looking?'}
                </Text>
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSwitchValueQ2(newSwitchValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={switchValueQ2}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      {/* Next Step */}
      <View
        {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['bottomButton'].style,
          dimensions.width
        )}
      >
        <Pressable
          style={StyleSheet.applyWidth({ minWidth: '100%' }, dimensions.width)}
        >
          {/* Button_Next */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const jobseekerResult = (
                    await XanoJobseekerApi.oNBOARDINGCreateNewJobseekerPOST(
                      Constants,
                      {
                        actively_looking: switchValueQ1,
                        currently_employed: switchValueQ2,
                        user_id: Constants['USER_ID'],
                      }
                    )
                  )?.json;
                  navigation.navigate('Onboarding2JobseekerScreen', {
                    jobseeker_ID: jobseekerResult,
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.branding.secondary,
                borderRadius: 100,
                fontFamily: 'Poppins_700Bold',
                maxWidth: { minWidth: Breakpoints.Laptop, value: 300 },
                minWidth: { minWidth: Breakpoints.Laptop, value: 300 },
              },
              dimensions.width
            )}
            title={'Next'}
          />
        </Pressable>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding1JobseekerScreen);
