import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { borderRadius: 100, height: 60, width: 60 }, props: {} },
    chat_image: {
      style: {
        borderRadius: 100,
        height: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Desktop, value: 30 },
        ],
        width: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Desktop, value: 30 },
        ],
      },
      props: {},
    },
    match_image: {
      style: { borderRadius: 20, height: 400, marginBottom: 10, width: '100%' },
      props: {},
    },
  });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({
    'Deck Swiper': { style: { position: 'absolute' }, props: {} },
  });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      style: {
        alignItems: 'center',
        borderWidth: 2,
        justifyContent: 'center',
        padding: 20,
      },
      props: {},
    },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    '18 Extra Bold': {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_800ExtraBold',
        fontSize: 18,
      },
      props: {},
    },
    'NOT WORKING': {
      style: {
        color: theme.colors.background.danger,
        fontFamily: 'Poppins_600SemiBold',
      },
      props: {},
    },
    Text: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    blockedbyRecipient: {
      style: {
        color: theme.colors.background.danger,
        fontFamily: 'Poppins_500Medium',
        fontSize: 12,
      },
      props: {},
    },
    blockedbyUser: {
      style: {
        color: palettes.Brand.Community_Primary,
        fontFamily: 'Poppins_500Medium',
        fontSize: 12,
      },
      props: {},
    },
    h1: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_700Bold',
        fontSize: 36,
        textAlign: 'center',
      },
      props: {},
    },
    h1_inverse: {
      style: { color: palettes.Brand['Light Inverse'] },
      props: {},
    },
    h2: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 18,
      },
      props: {},
    },
    'h2 inverse': {
      style: { color: palettes.Brand['Strong Inverse'] },
      props: {},
    },
    h3: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 14,
        marginTop: 4,
      },
      props: {},
    },
    'h3 bold': {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
      },
      props: {},
    },
    'h3 inverse': {
      style: {
        color: palettes.Brand['Medium Inverse'],
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    h4: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    'h4 inverse': {
      style: { color: palettes.Brand['Light Inverse'], fontSize: 12 },
      props: {},
    },
    label: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
        marginBottom: 4,
      },
      props: {},
    },
    menu_item: {
      style: {
        color: palettes.Brand['Light Inverse'],
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 16,
      },
      props: {},
    },
    menu_item_sub: { style: {}, props: {} },
    message_sender: {
      style: { fontFamily: 'Poppins_400Regular', paddingLeft: 4 },
      props: {},
    },
    question: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: 16,
      },
      props: {},
    },
    small_text: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    subHeading: {
      style: {
        color: theme.colors.text.medium,
        fontFamily: 'Poppins_400Regular',
        fontSize: 12,
      },
      props: {},
    },
    success_code_button: {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
      },
      props: {},
    },
    'text selected': {
      style: {
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    'text unselected': {
      style: {
        color: palettes.App['text placeholder'],
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    text_inverse: {
      style: { color: palettes.Brand['Light Inverse'], fontSize: 14 },
      props: {},
    },
    user_chat: {
      style: {
        color: theme.colors.branding.primary,
        flex: 1,
        fontFamily: 'Poppins_400Regular',
        paddingLeft: 8,
        paddingRight: 4,
        textAlign: 'right',
      },
      props: {},
    },
    warning: {
      style: {
        color: theme.colors.background.danger,
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 12,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      },
      props: {},
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
    Button_Cancel: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_600SemiBold',
      },
      props: {},
    },
    Button_Delete: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: 0,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        minHeight: 0,
        paddingBottom: 4,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
      },
      props: {},
    },
    Button_Edit: {
      style: {
        borderRadius: 50,
        fontFamily: 'Poppins_400Regular',
        fontSize: 10,
        maxWidth: 60,
        minHeight: 0,
        minWidth: 60,
        paddingBottom: 4,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
      },
      props: {},
    },
    Button_Primary_Small: {
      style: {
        borderRadius: 50,
        fontFamily: 'Poppins_400Regular',
        maxWidth: [
          { minWidth: Breakpoints.Desktop, value: 300 },
          { minWidth: Breakpoints.Laptop, value: 300 },
          { minWidth: Breakpoints.Mobile, value: 300 },
        ],
        minWidth: [
          { minWidth: Breakpoints.Laptop, value: 300 },
          { minWidth: Breakpoints.Mobile, value: 300 },
        ],
      },
      props: {},
    },
    Button_Secondary: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.secondary,
        borderRadius: 100,
        color: palettes.App.White,
        fontFamily: 'Poppins_400Regular',
        height: 54,
        justifyContent: 'center',
        maxWidth: [
          { minWidth: Breakpoints.Mobile, value: 300 },
          { minWidth: Breakpoints.Laptop, value: 300 },
        ],
        minWidth: [
          { minWidth: Breakpoints.Laptop, value: 300 },
          { minWidth: Breakpoints.Mobile, value: 300 },
        ],
        paddingLeft: 32,
        paddingRight: 32,
      },
      props: {},
    },
    button_CLEAR: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    button_inverse: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App.White,
        borderRadius: 50,
        borderWidth: 1,
        height: 54,
      },
      props: {},
    },
  });

export const PickerStyles = theme =>
  StyleSheet.create({
    picker: {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 100,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        paddingBottom: 8,
        paddingTop: 8,
        position: 'relative',
      },
      props: {},
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Delete Block': {
      style: {
        alignSelf: 'center',
        backgroundColor: palettes.App['Primary 2'],
        borderRadius: 18,
        margin: 20,
        padding: 20,
      },
      props: {},
    },
    align_right: {
      style: {
        alignItems: { minWidth: Breakpoints.Tablet, value: 'flex-end' },
        flex: { minWidth: Breakpoints.Tablet, value: 1 },
        paddingLeft: { minWidth: Breakpoints.Tablet, value: 10 },
      },
      props: {},
    },
    bottomButton: {
      style: {
        alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        alignItems: 'center',
        alignSelf: 'center',
        flex: { minWidth: Breakpoints.Laptop, value: 1 },
        flexDirection: { minWidth: Breakpoints.Desktop, value: 'row' },
        justifyContent: [
          { minWidth: Breakpoints.Desktop, value: 'center' },
          { minWidth: Breakpoints.Laptop, value: 'flex-end' },
        ],
        marginBottom: 20,
        marginLeft: 16,
        marginRight: 16,
        marginTop: [
          { minWidth: Breakpoints.Laptop, value: 20 },
          { minWidth: Breakpoints.Mobile, value: 20 },
        ],
        maxWidth: 300,
        minWidth: 300,
      },
      props: {},
    },
    chip: {
      style: {
        alignItems: 'center',
        backgroundColor: palettes.App['Primary 2'],
        borderRadius: 50,
        flex: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'inner CONTAINER': {
      style: {
        flex: { minWidth: Breakpoints.Tablet, value: 1 },
        justifyContent: [
          { minWidth: Breakpoints.Mobile, value: 'space-between' },
          { minWidth: Breakpoints.Tablet, value: 'flex-start' },
        ],
        maxWidth: [
          { minWidth: Breakpoints.Tablet, value: 1280 },
          { minWidth: Breakpoints.Mobile, value: 1280 },
        ],
      },
      props: {},
    },
    menu_items: { style: { marginLeft: 20 }, props: {} },
    outline_block: {
      style: {
        borderColor: palettes.App.Tab_Divider,
        borderRadius: 20,
        borderWidth: 2,
        flex: 1,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
      },
      props: {},
    },
    'screen CONTAINER': {
      style: {
        justifyContent: [
          { minWidth: Breakpoints.Mobile, value: 'space-between' },
          { minWidth: Breakpoints.Tablet, value: 'flex-start' },
        ],
      },
      props: {},
    },
    settings: {
      style: {
        borderColor: theme.colors.border.brand,
        borderRadius: 18,
        borderWidth: 1,
        flexDirection: 'row',
        gap: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
        padding: 20,
      },
      props: {},
    },
    'support group': {
      style: {
        borderColor: theme.colors.border.brand,
        borderRadius: 18,
        borderWidth: 1,
        flexDirection: 'row',
        gap: 20,
        marginBottom: 20,
        padding: 20,
      },
      props: {},
    },
    swipeableList: {
      style: { flex: 1, marginLeft: 20, marginTop: 20 },
      props: {},
    },
    'tab selected': {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        borderBottomWidth: 3,
        borderColor: theme.colors.branding.primary,
        flex: 1,
        marginRight: 20,
      },
      props: {},
    },
    'tab unselected': {
      style: {
        alignItems: 'center',
        alignSelf: 'center',
        borderBottomWidth: 3,
        borderColor: palettes.App['text placeholder'],
        flex: 1,
        marginRight: 20,
      },
      props: {},
    },
    'total Width CONTAINER': {
      style: {
        alignContent: { minWidth: Breakpoints.Desktop, value: 'center' },
        alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
        flex: [
          { minWidth: Breakpoints.Mobile, value: 1 },
          { minWidth: Breakpoints.Tablet, value: 1 },
        ],
        justifyContent: 'space-between',
        minWidth: { minWidth: Breakpoints.Desktop, value: 1280 },
      },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    InputField: {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 50,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 14 },
          { minWidth: Breakpoints.Mobile, value: 14 },
        ],
        marginTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Area': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 50,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: [
          {
            minWidth: Breakpoints.Tablet,
            value: theme.colors.branding.primary,
          },
          {
            minWidth: Breakpoints.Mobile,
            value: theme.colors.branding.primary,
          },
        ],
        flex: 1,
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    input_text_white: {
      style: {
        borderColor: theme.colors.text.light,
        borderRadius: 100,
        color: palettes.Brand['Medium Inverse'],
        fontFamily: 'Poppins_400Regular',
        paddingBottom: 16,
        paddingLeft: [
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Mobile, value: 16 },
        ],
        paddingRight: [
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Mobile, value: 16 },
        ],
        paddingTop: 16,
      },
      props: {},
    },
  });

export const AccordionGroupStyles = theme =>
  StyleSheet.create({
    Accordion: {
      style: {
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 16,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const MultiSelectPickerStyles = theme =>
  StyleSheet.create({
    multi_select_Picker: {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 100,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        paddingBottom: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { style: { flex: 1 }, props: {} } });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const WebViewStyles = theme =>
  StyleSheet.create({
    'HTML View': { style: { flex: 1 }, props: {} },
    'Web View': { style: { flex: 1 }, props: {} },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { style: { height: 1 }, props: {} } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: { style: { color: theme.colors.branding.primary }, props: {} },
    link_inverse: {
      style: {
        color: palettes.Brand['Strong Inverse'],
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
  });

export const SwipeableItemStyles = theme =>
  StyleSheet.create({
    'Swipeable Item': { style: { overflow: 'hidden' }, props: {} },
  });

export const SwipeableItemButtonStyles = theme =>
  StyleSheet.create({ swipe_edit: { style: {}, props: {} } });

export const H1Styles = theme =>
  StyleSheet.create({
    H1: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 32,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    'Switch Row': { style: { marginLeft: 0, marginRight: 0 }, props: {} },
  });

export const DatePickerStyles = theme =>
  StyleSheet.create({
    date_picker: {
      style: {
        borderColor: theme.colors.border.brand,
        borderRadius: 18,
        borderWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
        maxWidth: 180,
        paddingBottom: 4,
        paddingTop: 4,
      },
      props: { hideLabel: true },
    },
  });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const IconStyles = theme =>
  StyleSheet.create({
    icon_secondary: {
      style: {
        height: 24,
        marginRight: 0,
        maxHeight: 24,
        maxWidth: 24,
        width: 24,
      },
      props: { color: theme.colors.branding.secondary },
    },
    menu_Icon: { style: {}, props: { color: palettes.Brand['Light Inverse'] } },
  });

export const IconButtonStyles = theme =>
  StyleSheet.create({ 'icon button': { style: {}, props: {} } });

export const SliderStyles = theme =>
  StyleSheet.create({
    Slider: { style: { marginLeft: 12, marginRight: 12 }, props: {} },
  });

export const LinearGradientStyles = theme =>
  StyleSheet.create({
    'Linear Gradient': {
      style: { flex: 1, height: '100%', width: '100%' },
      props: {
        color1: theme.colors.branding.primary,
        color2: palettes.App['Primary 4'],
        color3: theme.colors.branding.secondary,
      },
    },
  });
