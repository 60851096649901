import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Link,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoUsersApi from '../apis/XanoUsersApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SignUpJobseekerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [INPUT_email, setINPUT_email] = React.useState('');
  const [INPUT_firstName, setINPUT_firstName] = React.useState('');
  const [INPUT_lastName, setINPUT_lastName] = React.useState('');
  const [INPUT_password, setINPUT_password] = React.useState('');
  const [PLACEHOLDER_email, setPLACEHOLDER_email] =
    React.useState('janedoe@email.com');
  const [PLACEHOLDER_firstName, setPLACEHOLDER_firstName] =
    React.useState('Jane');
  const [PLACEHOLDER_lastName, setPLACEHOLDER_lastName] = React.useState('Doh');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <LinearGradient
        color1={theme.colors.branding.primary}
        color2={theme.colors.branding.secondary}
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
          dimensions.width
        )}
      >
        <SimpleStyleKeyboardAwareScrollView
          enableAutomaticScroll={false}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          style={StyleSheet.applyWidth(
            {
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              flex: 1,
              justifyContent: 'center',
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: 800 },
                { minWidth: Breakpoints.Tablet, value: 600 },
              ],
              paddingBottom: 48,
              paddingLeft: 48,
              paddingRight: 48,
              paddingTop: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={imageSource(Images['LogoTransparentInWhite1928x183'])}
              style={StyleSheet.applyWidth(
                { height: 64, width: 150 },
                dimensions.width
              )}
            />
          </View>
          <Spacer bottom={8} left={8} right={8} top={8} />
          <View>
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.Brand.Surface,
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 20,
                },
                dimensions.width
              )}
            >
              {'Find your next career'}
            </Text>
            <Spacer left={8} right={8} bottom={4} top={4} />
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.Brand['Medium Inverse'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'When you want it, whatever it might look like.'}
            </Text>
          </View>
          <Spacer left={8} right={8} bottom={24} top={24} />
          <View>
            {/* First Name */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.Brand.Surface,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'First Name'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: palettes.Brand['Light Inverse'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.Brand.Surface}
                  name={'Feather/user'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setINPUT_firstName(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    autoCapitalize={'words'}
                    keyboardType={'default'}
                    placeholder={PLACEHOLDER_firstName.toString() ?? 'Jane Doh'}
                    placeholderTextColor={palettes.Brand['Light Inverse']}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                    value={INPUT_firstName}
                  />
                </View>
              </View>
            </View>
            {/* Spacer 2 */}
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Last Name */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.Brand.Surface,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Last Name'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: palettes.Brand['Light Inverse'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.Brand.Surface}
                  name={'Feather/user'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setINPUT_lastName(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    autoCapitalize={'words'}
                    keyboardType={'default'}
                    placeholder={PLACEHOLDER_lastName.toString() ?? 'Jane Doh'}
                    placeholderTextColor={palettes.Brand['Light Inverse']}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                    value={INPUT_lastName}
                  />
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Email */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.Brand.Surface,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Email'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: palettes.Brand['Light Inverse'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.Brand.Surface}
                  name={'MaterialIcons/alternate-email'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setINPUT_email(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'email-address'}
                    placeholder={
                      PLACEHOLDER_email.toString() ?? 'JaneDoh@email.com'
                    }
                    placeholderTextColor={palettes.Brand['Light Inverse']}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                    value={INPUT_email}
                  />
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Password */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.Brand.Surface,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Password'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: palettes.Brand['Light Inverse'],
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.Brand.Surface}
                  name={'MaterialIcons/lock-outline'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputValue => {
                      try {
                        setINPUT_password(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'default'}
                    placeholder={'MySecretPassword'}
                    placeholderTextColor={palettes.Brand['Light Inverse']}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'System',
                        fontSize: 18,
                        fontWeight: '400',
                      },
                      dimensions.width
                    )}
                    value={INPUT_password}
                  />
                </View>
              </View>
            </View>
          </View>
          <Spacer left={8} right={8} bottom={32} top={32} />
          {/* BUTTON CONTAINER */}
          <View>
            {/* Sign Up */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    const newSignUpResult = (
                      await XanoUsersApi.addANewJobseekerSignUpPOST(Constants, {
                        email: INPUT_email,
                        first_name: INPUT_firstName,
                        last_name: INPUT_lastName,
                        password: INPUT_password,
                        primary_role: 1,
                      })
                    )?.json;
                    const authTokenResult = newSignUpResult?.authToken;
                    const idResult = newSignUpResult?.id;
                    setGlobalVariableValue({
                      key: 'AUTH_HEADER',
                      value: 'Bearer ' + authTokenResult,
                    });
                    setGlobalVariableValue({
                      key: 'USER_ID',
                      value: idResult,
                    });
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('PlanOptionsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'theme.colors.transparent',
                  borderBottomWidth: 3,
                  borderColor: palettes.Brand['Medium Inverse'],
                  borderLeftWidth: 3,
                  borderRadius: 50,
                  borderRightWidth: 3,
                  borderTopWidth: 3,
                  color: palettes.Brand.Surface,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 16,
                  paddingBottom: 16,
                  paddingTop: 16,
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'Sign Up'}
            />
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* SIGN IN CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center' },
                dimensions.width
              )}
            >
              {/* Link  Sign In */}
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.Brand.Surface,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Already have an account? Sign in'}
              />
            </View>
          </View>
        </SimpleStyleKeyboardAwareScrollView>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(SignUpJobseekerScreen);
