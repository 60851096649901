import React from 'react';
import {
  Button,
  Icon,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as XanoRecruiterApi from '../apis/XanoRecruiterApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getPickerOptions from '../global-functions/getPickerOptions';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { user_ID: 63 };

const Onboarding1RecruiterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [EducationLevelOptions, setEducationLevelOptions] = React.useState('');
  const [ID_company, setID_company] = React.useState(0);
  const [ID_educationLevel, setID_educationLevel] = React.useState(0);
  const [INPUT_CompanyName, setINPUT_CompanyName] = React.useState('');
  const [INPUT_jobTitle, setINPUT_jobTitle] = React.useState('');
  const [INPUT_phone, setINPUT_phone] = React.useState('');
  const [LABEL_FindCompanyName, setLABEL_FindCompanyName] =
    React.useState('Find your Company');
  const [LABEL_jobTitle, setLABEL_jobTitle] = React.useState(
    'What is your job title?'
  );
  const [LABEL_phone, setLABEL_phone] = React.useState(
    'Enter your contact number'
  );
  const [PV_status, setPV_status] = React.useState('');
  const [SV_currentlyHiring, setSV_currentlyHiring] = React.useState(false);
  const [SV_partofTeam, setSV_partofTeam] = React.useState(false);
  const [input_CompanyName, setInput_CompanyName] = React.useState('');
  const [iseditingCompany, setIseditingCompany] = React.useState(false);
  const [label_FindCompanyName, setLabel_FindCompanyName] = React.useState('');
  const [options_recrutierStatus, setOptions_recrutierStatus] =
    React.useState('');
  const [pv_EducationLevel, setPv_EducationLevel] = React.useState('');
  const [searchCompanyValue, setSearchCompanyValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { maxWidth: { minWidth: Breakpoints.Laptop, value: 1280 } },
        dimensions.width
      )}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
            { margin: 20 }
          ),
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* SCREEN CONTAINER */}
          <View>
            {/* Heading Section */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 10 },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(
                  Images['ElephantLookingLeftJobzMaverickFinal']
                )}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { alignSelf: 'flex-start' }
                  ),
                  dimensions.width
                )}
              >
                {"Let's get you started . . ."}
              </Text>
            </View>
            {/* Description */}
            <View>
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  "Before you can look for your next great candidate or even connect with potential industry colleagues, we need to know a little more about you. All fields can be modified later, but should be completed now.\n\nSo grab a cup and let's begin and it will be all completed before you finish your drink."
                }
              </Text>
            </View>
            {/* BODY CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 10,
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Block Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'Tell us more about you . . .'}
              </Text>
              {/* Q1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row-reverse',
                    gap: 40,
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* Currently Employed */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Are you currently hiring?'}
                </Text>
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSV_currentlyHiring(newSwitchValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={SV_currentlyHiring}
                />
              </View>
              {/* Q2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row-reverse',
                    gap: 40,
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* Part of a Team */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Are you part of a team?'}
                </Text>
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSV_partofTeam(newSwitchValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={SV_partofTeam}
                />
              </View>
            </View>
            {/* Input Company Name */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
            >
              {/* Company Add Block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* LABEL Company Name */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['label'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['label'].style,
                    dimensions.width
                  )}
                >
                  {'Company*'}
                </Text>

                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('AddNewCompanyScreen', {
                        newCompanyResponse: searchCompanyValue,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Add Company */}
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['icon_secondary'].props}
                    name={'AntDesign/plus'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                      dimensions.width
                    )}
                  />
                </Pressable>
              </View>
              {/* Find Company */}
              <View>
                {/* Input Company Name */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newInputCompanyNameValue => {
                    try {
                      const value9xKQkswF = newInputCompanyNameValue;
                      const newCompanyResult = value9xKQkswF;
                      setSearchCompanyValue(value9xKQkswF);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newInputCompanyNameValue => {
                    try {
                      if (newInputCompanyNameValue?.length > 0) {
                      } else {
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onFocus={() => {
                    try {
                      setIseditingCompany(true);
                      setSearchCompanyValue('');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                  placeholder={LABEL_FindCompanyName.toString()}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['InputField'].style,
                      { borderRadius: 18 }
                    ),
                    dimensions.width
                  )}
                  value={searchCompanyValue}
                />
                <>
                  {!(searchCompanyValue?.length && iseditingCompany) ? null : (
                    <XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET
                      handlers={{
                        onData: fetchData => {
                          try {
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                      search={searchCompanyValue}
                    >
                      {({ loading, error, data, refetchDropDownCompany }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* MESSAGE Add Your Company */}
                            <>
                              {fetchData?.dd_company?.length ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'You will need to add your company, click on +'
                                  }
                                </Text>
                              )}
                            </>
                            <>
                              {!(searchCompanyValue?.length > 2) ? null : (
                                <SimpleStyleFlatList
                                  data={fetchData?.dd_company}
                                  decelerationRate={'normal'}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index?.toString() ??
                                    JSON.stringify(listData)
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'ikbUZgOF'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  pagingEnabled={false}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            setSearchCompanyValue(
                                              listData?.company_name
                                            );
                                            setINPUT_CompanyName(null);
                                            setID_company(listData?.id);
                                            setIseditingCompany(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Company Name to Select */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.company_name}
                                          {', '}
                                          {listData?.Location}
                                        </Text>
                                      </Pressable>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  snapToAlignment={'start'}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET>
                  )}
                </>
              </View>
            </View>
            {/* Job Title */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
            >
              {/* LABEL Job Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Job Title'}
              </Text>
              {/* Input Job title */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputJobTitleValue => {
                  const textInputValue = newInputJobTitleValue;
                  try {
                    setINPUT_jobTitle(newInputJobTitleValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={LABEL_jobTitle.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  dimensions.width
                )}
                value={INPUT_jobTitle}
              />
            </View>
            {/* Phone */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
            >
              {/* LABEL PHONE */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Phone'}
              </Text>
              {/* input Phone */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputPhoneValue => {
                  const textInputValue = newInputPhoneValue;
                  try {
                    setINPUT_phone(newInputPhoneValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={LABEL_phone.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  dimensions.width
                )}
                value={INPUT_phone}
              />
            </View>
            {/* Input Recrutier Status */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
            >
              {/* LABEL Recrutier Status */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Main Objective*'}
              </Text>

              <XanoOptionSets$DropdownsApi.FetchDropDownRecruiterStatusGET
                handlers={{
                  onData: fetchData => {
                    try {
                      const recrtuierStatusResult = getPickerOptions(
                        fetchData?.dd_recruiterStatus,
                        'status'
                      );
                      setOptions_recrutierStatus(recrtuierStatusResult);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
              >
                {({ loading, error, data, refetchDropDownRecruiterStatus }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.base}
                      dropDownBorderColor={theme.colors.border.base}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      iconSize={24}
                      leftIconMode={'inset'}
                      onValueChange={newPickerValue => {
                        try {
                          const valuexGtkIcwv = newPickerValue;
                          const newEducationLevelResult = valuexGtkIcwv;
                          setPV_status(valuexGtkIcwv);
                          setID_educationLevel(newPickerValue?.id);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      dropDownTextColor={theme.colors.branding.primary}
                      mode={'dropdown-modal'}
                      options={options_recrutierStatus}
                      placeholder={'Please choose'}
                      placeholderTextColor={theme.colors.branding.primary}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={PV_status}
                    />
                  );
                }}
              </XanoOptionSets$DropdownsApi.FetchDropDownRecruiterStatusGET>
            </View>
          </View>
        </View>
      </View>
      {/* Next Step */}
      <View
        {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['bottomButton'].style,
            { alignContent: null }
          ),
          dimensions.width
        )}
      >
        {/* Button_Next */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const recruiterID = (
                  await XanoRecruiterApi.oNBOARDINGCreateNewRecruiterPOST(
                    Constants,
                    {
                      company_id: ID_company,
                      currently_hiring: SV_currentlyHiring,
                      phone: INPUT_phone,
                      recruiter_status: PV_status,
                      title: INPUT_jobTitle,
                    }
                  )
                )?.json;
                navigation.navigate('Onboarding2RecruiterScreen', {
                  recruiter_ID: recruiterID,
                });
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
            dimensions.width
          )}
          title={'Next'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding1RecruiterScreen);
