import React from 'react';
import {
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzConnectApi from '../apis/XanoJobzConnectApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { ID_selectedUser: 30 };

const JobzConnectRecruiterUserProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [SV_cell, setSV_cell] = React.useState(false);
  const [SV_email, setSV_email] = React.useState(false);
  const [city, setCity] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [photoUrl, setPhotoUrl] = React.useState('');
  const [showCell, setShowCell] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);
  const [state, setState] = React.useState('');
  const [status, setStatus] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { flex: 1, justifyContent: 'space-evenly' },
        dimensions.width
      )}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'space-between' },
                  { minWidth: Breakpoints.Tablet, value: 'space-between' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Heading */}
            <View>
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { marginBottom: 10, marginLeft: 20, marginRight: 20 }
                  ),
                  dimensions.width
                )}
              >
                {'jobzProfile'}
              </Text>
              {/* Image Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    flex: 1,
                    marginBottom: 10,
                  },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['match_image'].props}
                  resizeMode={'cover'}
                  source={imageSource(`${photoUrl}`)}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['match_image'].style,
                      { borderRadius: 1000, height: 80, width: 80 }
                    ),
                    dimensions.width
                  )}
                />
                {/* Demo User */}
                <>
                  {!'.demo' ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {'DEMO USER'}
                    </Text>
                  )}
                </>
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', gap: 4 },
                    dimensions.width
                  )}
                >
                  {/* First Name */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['18 Extra Bold'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['18 Extra Bold'].style,
                      dimensions.width
                    )}
                  >
                    {firstName}
                  </Text>
                  {/* Last Name */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['18 Extra Bold'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['18 Extra Bold'].style,
                      dimensions.width
                    )}
                  >
                    {lastName}
                  </Text>
                </View>
                {/* Location */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {city}
                  {', '}
                  {state}
                </Text>
                {/* Status */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {status}
                </Text>
              </View>
            </View>
            {/* RecruiterProfile */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    maxHeight: [
                      { minWidth: Breakpoints.Mobile, value: 560 },
                      { minWidth: Breakpoints.Tablet, value: 900 },
                      { minWidth: Breakpoints.Laptop, value: 800 },
                    ],
                  },
                  dimensions.width
                )}
              >
                <XanoJobzConnectApi.FetchGetASingleRecruiterGET
                  handlers={{
                    onData: fetchData => {
                      try {
                        console.log(fetchData);
                        setFirstName(fetchData?.first_name);

                        const valuejyAHtcUd = fetchData?.last_name;
                        const safdasf = valuejyAHtcUd;
                        setLastName(valuejyAHtcUd);
                        const valuePsBm7haO =
                          fetchData?._image_of_user?.image?.url;
                        const dsafsdf = valuePsBm7haO;
                        setPhotoUrl(valuePsBm7haO);
                        setCity(
                          fetchData?._recruiter_of_user?._company?._location
                            ?.city
                        );
                        setState(
                          fetchData?._recruiter_of_user?._company?._location
                            ?._os_states_usa?.state
                        );
                        setShowEmail(fetchData?._settings_of_user?.show_email);
                        setShowCell(fetchData?._settings_of_user?.show_cell);
                        setStatus(
                          fetchData?._recruiter_of_user?._os_recruiterstatus
                            ?.status
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  user_id={
                    props.route?.params?.ID_selectedUser ??
                    defaultProps.ID_selectedUser
                  }
                >
                  {({ loading, error, data, refetchGetASingleRecruiter }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchData}
                        decelerationRate={'normal'}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'K4QzECSM'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        pagingEnabled={false}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* SETTING DATA CONTAINER */}
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'outline_block'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)[
                                      'outline_block'
                                    ].style,
                                    { flex: null, marginBottom: 10 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* Display Email */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, flexDirection: 'row', gap: 40 },
                                    dimensions.width
                                  )}
                                >
                                  {/* LABEL CONTAINER */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { minWidth: 50 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LABEL EMAIL */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'label'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['label']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Email'}
                                    </Text>
                                  </View>
                                  {/* email */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        { flex: 1 }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.email}
                                  </Text>
                                </View>
                                {/* Display Cell */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, flexDirection: 'row', gap: 40 },
                                    dimensions.width
                                  )}
                                >
                                  {/* LABEL CONTAINER */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { minWidth: 50 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LABEL CELL */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'label'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['label']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Phone'}
                                    </Text>
                                  </View>
                                  {/* cell */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        { flex: 1 }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.cell}
                                  </Text>
                                </View>
                              </View>
                              {/* Recruiter Data */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 20,
                                    borderWidth: 2,
                                    marginBottom: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Company Name */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['h2']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['h2'].style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    listData?._recruiter_of_user?._company
                                      ?.company_name
                                  }
                                </Text>
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?._recruiter_of_user?.title}
                                </Text>
                              </View>
                              {/* Blocks */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    marginLeft: 20,
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Row 1 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      flexDirection: 'row',
                                      gap: 10,
                                      justifyContent: 'space-around',
                                      marginBottom: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Left Panel */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        borderColor: theme.colors.border.brand,
                                        borderRadius: 20,
                                        borderWidth: 2,
                                        flex: 1,
                                        padding: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Open Jobs Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Open Jobs'}
                                    </Text>
                                    {/* Open Jobs Value */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?._recruiter_of_user?.open_jobs}
                                    </Text>
                                  </View>
                                  {/* Right Panel */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        borderColor: theme.colors.border.brand,
                                        borderRadius: 20,
                                        borderWidth: 2,
                                        flex: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 1,
                                        },
                                        padding: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Average Openings Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Average Openings'}
                                    </Text>
                                    {/* Average Openings Value */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        listData?._recruiter_of_user
                                          ?._os_averagenojobs?.average_no_jobs
                                      }
                                    </Text>
                                  </View>
                                </View>
                                {/* Row 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      flexDirection: 'row',
                                      gap: 10,
                                      justifyContent: 'space-around',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Left Panel */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        borderColor: theme.colors.border.brand,
                                        borderRadius: 20,
                                        borderWidth: 2,
                                        flex: 1,
                                        padding: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Part of A Team Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Part of a Team'}
                                    </Text>
                                    {/* Part of Team Value_NO */}
                                    <>
                                      {!(
                                        listData?._recruiter_of_user
                                          ?.part_of_team === false
                                      ) ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { alignSelf: 'center' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'No'}
                                        </Text>
                                      )}
                                    </>
                                    {/* Part of Team Value Yes */}
                                    <>
                                      {!(
                                        listData?._recruiter_of_user
                                          ?.part_of_team === true
                                      ) ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { alignSelf: 'center' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Yes'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Right Panel */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        borderColor: theme.colors.border.brand,
                                        borderRadius: 20,
                                        borderWidth: 2,
                                        flex: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 1,
                                        },
                                        padding: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Average Time to Fill Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Average Time to Fill'}
                                    </Text>
                                    {/* Average Openings Value */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        listData?._recruiter_of_user
                                          ?._os_averagetimetofill
                                          ?.average_time_to_fill
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              {/* Specialties */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 20,
                                    borderWidth: 2,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginTop: 10,
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Specialities Label */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Specialties:'}
                                </Text>
                                {/* sublist */}
                                <SimpleStyleFlatList
                                  data={
                                    listData?._recruiter_of_user
                                      ?.os_recruitingspecialty_id
                                  }
                                  decelerationRate={'normal'}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(sublistData, index) =>
                                    sublistData?.id ??
                                    sublistData?.uuid ??
                                    index?.toString() ??
                                    JSON.stringify(sublistData)
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={JSON.stringify(
                                    listData?._recruiter_of_user
                                      ?.os_recruitingspecialty_id
                                  )}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  pagingEnabled={false}
                                  renderItem={({ item, index }) => {
                                    const sublistData = item;
                                    return (
                                      <>
                                        {/* Specialities Value */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'h3 bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {sublistData?.speciality}
                                        </Text>
                                      </>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  snapToAlignment={'start'}
                                />
                              </View>
                              {/* Job Levels */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.border.brand,
                                    borderRadius: 20,
                                    borderWidth: 2,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginTop: 10,
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Job Levels Label */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Job Levels:'}
                                </Text>
                                {/* joblevelsList */}
                                <SimpleStyleFlatList
                                  data={
                                    listData?._recruiter_of_user?.os_joblevel_id
                                  }
                                  decelerationRate={'normal'}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(joblevelsListData, index) =>
                                    joblevelsListData?.id ??
                                    joblevelsListData?.uuid ??
                                    index?.toString() ??
                                    JSON.stringify(joblevelsListData)
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={JSON.stringify(
                                    listData?._recruiter_of_user?.os_joblevel_id
                                  )}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  pagingEnabled={false}
                                  renderItem={({ item, index }) => {
                                    const joblevelsListData = item;
                                    return (
                                      <>
                                        {/* Job Leves Value */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'h3 bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {joblevelsListData?.job_level}
                                        </Text>
                                      </>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  snapToAlignment={'start'}
                                />
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        snapToAlignment={'start'}
                      />
                    );
                  }}
                </XanoJobzConnectApi.FetchGetASingleRecruiterGET>
              </SimpleStyleScrollView>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzConnectRecruiterUserProfileScreen);
