import React from 'react';
import { Button, ScreenContainer, StarRating, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XANOJobzR8terApi from '../apis/XANOJobzR8terApi.js';
import BackNavigationBlock from '../components/BackNavigationBlock';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { rating_id: 44, recruiterID: 30 };

const JobzR8terIndividualScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_education, setID_education] = React.useState(0);
  const [ID_educationLevel, setID_educationLevel] = React.useState(0);
  const [ID_insitution, setID_insitution] = React.useState(0);
  const [ID_studyTitle, setID_studyTitle] = React.useState(0);
  const [datepicker_GraduationDate, setDatepicker_GraduationDate] =
    React.useState(0);
  const [input_StudyTitle, setInput_StudyTitle] = React.useState('');
  const [show_findRecruiter, setShow_findRecruiter] = React.useState(false);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <BackNavigationBlock />
          {/* SCREEN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            {/* Heading */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              {/* jobR8ter */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'jobzR8ter'}
              </Text>
            </View>
          </View>
          {/* FETCH CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            <XANOJobzR8terApi.FetchGetSingleRatingGET
              ratingId={
                props.route?.params?.rating_id ?? defaultProps.rating_id
              }
              userRecruiterId={
                props.route?.params?.recruiterID ?? defaultProps.recruiterID
              }
            >
              {({ loading, error, data, refetchGetSingleRating }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* HEADING CONTAINER */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 20 },
                        dimensions.width
                      )}
                    >
                      {/* Recruiter */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['h2'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['h2'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?._recruiter_of_user?._userR8ter?.first_name}{' '}
                        {fetchData?._recruiter_of_user?._userR8ter?.last_name}
                      </Text>
                      {/* Internal Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?._recruiter_of_user?.title}
                      </Text>
                      {/* Company */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['h3'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['h3'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?._recruiter_of_user?._company?.company_name}
                      </Text>
                      {/* Email */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?._recruiter_of_user?._userR8ter?.email}
                      </Text>
                      <StarRating
                        activeColor={theme.colors.branding.primary}
                        inactiveColor={theme.colors.border.base}
                        isEditable={true}
                        maxStars={5}
                        starSize={16}
                        defaultValue={fetchData?.combined_score}
                      />
                    </View>
                    {/* RESPONSE CONTAINER */}
                    <View
                      style={StyleSheet.applyWidth(
                        { gap: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Label Questions */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['h3'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['h3'].style,
                          dimensions.width
                        )}
                      >
                        {'Responses to Rating Questions'}
                      </Text>
                      {/* Question 1 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            gap: 10,
                            justifyContent: {
                              minWidth: Breakpoints.Tablet,
                              value: 'flex-start',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Question */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { paddingRight: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Have you ever been ghosted by this recuiter?'}
                        </Text>
                        {/* Answers */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['align_right']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['align_right'].style,
                            dimensions.width
                          )}
                        >
                          {/* Answer No */}
                          <>
                            {fetchData?.q1_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                            )}
                          </>
                          {/* Answer Yes */}
                          <>
                            {!fetchData?.q1_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                      {/* Question 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', gap: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Question */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { paddingRight: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Did this recruiter act professionally during your interview?'
                          }
                        </Text>
                        {/* Answers */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['align_right']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['align_right'].style,
                            dimensions.width
                          )}
                        >
                          {/* Answer No */}
                          <>
                            {fetchData?.q2_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                            )}
                          </>
                          {/* Answer Yes */}
                          <>
                            {!fetchData?.q2_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                      {/* Question 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', gap: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Question */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { paddingRight: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Did this recruiter respond to you in a timely manner?'
                          }
                        </Text>
                        {/* Answers */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['align_right']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['align_right'].style,
                            dimensions.width
                          )}
                        >
                          {/* Answer No */}
                          <>
                            {fetchData?.q3_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                            )}
                          </>
                          {/* Answer Yes */}
                          <>
                            {!fetchData?.q3_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                      {/* Question 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', gap: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Question */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { paddingRight: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Did this recruiter provide feedback as to your progress through the process?'
                          }
                        </Text>
                        {/* Answers */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['align_right']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['align_right'].style,
                            dimensions.width
                          )}
                        >
                          {/* Answer No */}
                          <>
                            {fetchData?.q4_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                            )}
                          </>
                          {/* Answer Yes */}
                          <>
                            {!fetchData?.q4_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                      {/* Question 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', gap: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Question */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { paddingRight: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'Would you be likely to apply for another role with this company based on your experience with this recruiter?'
                          }
                        </Text>
                        {/* Answers */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['align_right']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['align_right'].style,
                            dimensions.width
                          )}
                        >
                          {/* Answer No */}
                          <>
                            {fetchData?.q5_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'No'}
                              </Text>
                            )}
                          </>
                          {/* Answer Yes */}
                          <>
                            {!fetchData?.q5_response ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Yes'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                      {/* Label Qualities */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['h3'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['h3'].style,
                          dimensions.width
                        )}
                      >
                        {'Responses to Rating Qualities'}
                      </Text>
                      {/* Qualities */}
                      <View>
                        {/* List of Qualities */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.quality_id?.[0]}
                          {' organized'}
                        </Text>
                      </View>
                      {/* Rating Date */}
                      <View>
                        {/* Label Rating Date */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['h3'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['h3'].style,
                            dimensions.width
                          )}
                        >
                          {'Rating Date'}
                        </Text>
                        {/* Rating Date */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {convertTime(fetchData?.created_at)}
                        </Text>
                      </View>
                    </View>
                    {/* BUTTON CONTAINER */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          margin: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Button_Delete */}
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const jobzStoryResponse = (
                                await XANOJobzR8terApi.deleteRatingDELETE(
                                  Constants,
                                  { ratingId: fetchData?.id }
                                )
                              )?.json;
                              navigation.navigate('JobzR8terListScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                              .style,
                            { fontFamily: 'Poppins_700Bold' }
                          ),
                          dimensions.width
                        )}
                        title={'Delete Rating'}
                      />
                      {/* Button_Cancel */}
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button_Cancel']
                              .style,
                            {
                              borderRadius: 100,
                              fontFamily: 'Poppins_700Bold',
                              maxWidth: [
                                { minWidth: Breakpoints.Laptop, value: 300 },
                                { minWidth: Breakpoints.Mobile, value: 300 },
                              ],
                              minWidth: [
                                { minWidth: Breakpoints.Laptop, value: 300 },
                                { minWidth: Breakpoints.Mobile, value: 300 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                  </>
                );
              }}
            </XANOJobzR8terApi.FetchGetSingleRatingGET>
          </View>
        </View>
        <NavigationBottomBlock />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzR8terIndividualScreen);
