import React from 'react';
import {
  Button,
  Divider,
  IconButton,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const KitchenJobseekerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [nav_active, setNav_active] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: 'Kitchen',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { justifyContent: 'space-between', marginTop: 20 },
        dimensions.width
      )}
    >
      {/* TOTAL WIDTH CONTAINER */}
      <View
        {...GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['total Width CONTAINER'].style,
          dimensions.width
        )}
      >
        {/* INNER CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['inner CONTAINER'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['inner CONTAINER'].style,
            dimensions.width
          )}
        >
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20, marginTop: 20 },
              dimensions.width
            )}
          >
            {/* Mascot */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', marginBottom: 10 },
                dimensions.width
              )}
            >
              <View>
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(
                    Images['ElephantLookingLeftJobzMaverickFinal']
                  )}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Welcome Back */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 20 },
                dimensions.width
              )}
            >
              {/* Welcome */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h3'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h3'].style,
                    { marginBottom: 4 }
                  ),
                  dimensions.width
                )}
              >
                {'Welcome back,  '}
                {Constants['FIRST_NAME']}
              </Text>
              {/* Module Name */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'The Kitchen'}
              </Text>
              {/* Description */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'This is where you can access all the modules you have access to as part of your membership. Pick a plate and get started.'
                }
              </Text>
            </View>
            {/* Menu Links */}
            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-around', marginTop: 10 },
                dimensions.width
              )}
            >
              {/* Row 1 Links */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-evenly',
                  },
                  dimensions.width
                )}
              >
                {/* Touchable JobzStory */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzStorySummaryScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzStory Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzStory'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzStory */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzStory'}
                  </Text>
                </Touchable>
                {/* Touchable JobzZone */}
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SELECTED',
                        value: 'zone',
                      });
                      navigation.navigate('JobzZoneScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzZone Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['Primary 2'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzZone'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzZone */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzZone'}
                  </Text>
                </Touchable>
                {/* Touchable JobzVault */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzVaultScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzVault Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 2'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzVault'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzVault */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10, paddingRight: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzVault'}
                  </Text>
                </Touchable>
              </View>
              {/* Row 2 Links */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-evenly',
                  },
                  dimensions.width
                )}
              >
                {/* Touchable JobzForum */}
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SELECTED',
                        value: 'forum',
                      });
                      navigation.navigate('JobzForumScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzForum Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 3'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzCafe'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzForum */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzForum'}
                  </Text>
                </Touchable>
                {/* Touchable JobzChat */}
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SELECTED',
                        value: 'chat',
                      });
                      navigation.navigate('JobzChatScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzChat Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'center',
                        backgroundColor: palettes.App['Primary 3'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzChat'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzChat */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzChat'}
                  </Text>
                </Touchable>
                {/* Touchable JobzConnect */}
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SELECTED',
                        value: 'connect',
                      });
                      navigation.navigate('JobzConnectScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzConnect Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['Primary 3'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzConnect'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzConnect */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzConnect'}
                  </Text>
                </Touchable>
              </View>
              {/* Row 3 Links */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-evenly',
                  },
                  dimensions.width
                )}
              >
                {/* Touchable JobzR8ter */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzR8terListScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzR8ter Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        backgroundColor: palettes.App['Primary 3'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzCafeRater'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzR8ter */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzR8ter'}
                  </Text>
                </Touchable>
                {/* Touchable JobzTalk */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzTalkScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzTalk Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 4'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzTalk'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzTalk */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzTalk'}
                  </Text>
                </Touchable>
                {/* Touchable JobzTarget */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzTargetScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzTarget Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 4'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzTarget'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzTarget */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10, paddingRight: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzTarget'}
                  </Text>
                </Touchable>
              </View>
              {/* Row 4 Links */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-evenly',
                  },
                  dimensions.width
                )}
              >
                {/* Touchable JobzTrust */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzTrustScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzTrust Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Primary 4'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzTrust'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzTrust */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzTrust'}
                  </Text>
                </Touchable>
                {/* Touchable JobzPrep */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzPrepScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzPrep Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        backgroundColor: palettes.App['Primary 4'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzPrep'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzPrep */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { alignSelf: 'center', fontSize: 10, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzPrep'}
                  </Text>
                </Touchable>
                {/* Touchable JobzFunnel */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('JobzFunnelScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* JobzFunnel Plate */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        backgroundColor: palettes.App['Primary 4'],
                        borderRadius: 100,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(Images['JobzFunnel'])}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 40, width: 40 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* jobzFunnel */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { fontSize: 10, paddingRight: 8 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'jobzFunnel'}
                  </Text>
                </Touchable>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { paddingTop: 10 },
                dimensions.width
              )}
            >
              <Divider
                {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                color={theme.colors.border.brand}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  dimensions.width
                )}
              />
              {/* Resources */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    { paddingBottom: 4, paddingTop: 4 }
                  ),
                  dimensions.width
                )}
              >
                {'Resources'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', gap: 20 },
                  dimensions.width
                )}
              >
                {/* KnowledgeBase */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <IconButton
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://jobzcafe.outseta.com/support/kb'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    size={32}
                    icon={'SimpleLineIcons/question'}
                  />
                </View>
                {/* Support Ticket */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <IconButton
                    onPress={() => {
                      try {
                        navigation.navigate('SupportTicketScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'SimpleLineIcons/rocket'}
                  />
                </View>
                {/* JobzMaverick */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <IconButton
                    onPress={() => {
                      try {
                        navigation.navigate('JobzMaverickScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'SimpleLineIcons/earphones-alt'}
                  />
                </View>
              </View>
            </View>
            {/* Logout */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Desktop,
                    value: 'center',
                  },
                  alignSelf: 'flex-start',
                  flex: { minWidth: Breakpoints.Desktop, value: 1 },
                },
                dimensions.width
              )}
            >
              {/* Sign Out Button */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'is_loading',
                      value: true,
                    });
                    setGlobalVariableValue({
                      key: 'AUTH_HEADER',
                      value: '',
                    });
                    undefined;
                    undefined;
                    undefined;
                    setGlobalVariableValue({
                      key: 'is_loading',
                      value: false,
                    });
                    navigation.navigate('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: null,
                    borderRadius: 100,
                    color: theme.colors.branding.primary,
                    fontFamily: 'Poppins_700Bold',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
                title={'Log out'}
              />
            </View>
          </SimpleStyleScrollView>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(KitchenJobseekerScreen);
